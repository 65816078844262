<template>
  <div class="flex flex-col">

    <div class="overflow-y-auto messages rte">
      <div class="p-4 text-sm bg-orange-100">
        <div v-for="(message, index) in messages" :key="index">
          <h2 class="text-base font-medium">{{ message.title }}</h2>
          <div class="mt-2" v-html="message.text"></div>
          <div v-html="message.text"></div>
          <div v-html="message.text"></div>
        </div>
      </div>
    </div>
    
    <div class="flex flex-col-reverse items-center justify-between flex-1 mt-4 md:flex-row md:space-y-0" >  

      <a        
        href
        class="inline-block mt-4 text-sm underline md:mt-0"
        :disabled="isLoadingMessages"
        @click.prevent="onCancel"
        v-html="$tk('Common.Actions.Cancel')"
      ></a>

      <div class="flex items-center mt-4 space-x-4">

        <a        
          href
          class="inline-block text-sm text-red-600 underline md:mt-0 hover:text-red-700"
          :disabled="isLoadingMessages"
          @click.prevent="onReject"
          v-html="$tk('Gdpr.RejectAndDeactivateUser')"
        ></a>

        <PButton           
          color="primary" 
          @click="onAccept"
          :disabled="isLoadingMessages"
          :loading="isSubmitting"          
        >
          <span v-html="$tk('Gdpr.AcceptAndContinueLogin')"></span>
        </PButton>

      </div>
    
    </div>

  </div>
</template>

<script>

import http from "@/http"
import { filter } from "lodash"
import TokenService from "../services/TokenService"

export default {
  
  name: "login",
  
  data() {
    return {
      isLoadingMessages: false,
      isSubmitting: false,
      messages: []
    }
  },
  
  methods: {

    handleError () {
      alert(this.$tk("Gdpr.AnUnexpectedErrorOccuredPleaseTryAgain", true))
    },

    async onCancel () {
      try {
        await http.post("logout")
        TokenService.removeToken()
        this.$router.push({ name: "login" })
      } catch {
        this.handleError()
      }
    },

    async onReject () {
      this.isSubmitting = true      
      await this.submit(false)      
      TokenService.removeToken()
      this.$router.push({ name: "login" })
      this.isSubmitting = false
    },

    async onAccept () {
      this.isSubmitting = true      
      await this.submit(true)
      this.$router.push({ name: "app" })
      this.isSubmitting = false
    },

    async submit (accept) {
      try {
        await http.post("UserGdpr", { accept })      
      } catch {        
        this.handleError()
      }
    }

  },

  async mounted () {

    this.isLoadingMessages = true
    
    try {
      const messages = await http.get("Messages", { 
        params: { 
          type: "login",
          taskId: "gdpr"
        }
      })
      this.messages = filter(messages, m => !!m.text)
    } catch {      
      this.messages = []
    }

    this.isLoadingMessages = false
  }
}
</script>

<style scoped>

.messages {
  
  height: 300px;
}

@screen lg {
  .messages {
    width: 640px;
  }
}
</style>